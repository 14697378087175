import * as api from "./ApiClient";
import * as apiBase from "./ApiClientBase";
import { Localizer } from "./Localizer";
import * as luxon from 'luxon';

export class Pair<T> {
    public edited: T;
    public original: T;

    constructor(edited: T, original: T) {
        this.edited = edited;
        this.original = original;
    }
}

export enum PasteOperationType {
    none,
    copy,
    cut
}

export class PasteOperation {
    public Fso: api.FileSystemObjectContent | null
    public Type: PasteOperationType

    constructor() {
        this.Fso = null;
        this.Type = PasteOperationType.none;
    }
}

export class ListDetailsPropertyItems {
    public Property!: api.ListPropertyInfo;
    public ShowDetails: boolean = false;
    public Modified: boolean = false;
}

export class ListContentsDetails {
    public List: string = "";
    public Contents: { [key: string]: any } = {};
    public ShowDetails: boolean = false;
    public Modified: boolean = false;
    public Delete: boolean = false;
    public ShowChildDetails: boolean = false;
    public ChildContents: ListContentsDetails[] = [];
}

export class ListContentChildDetails {
    public List!: api.ListInfo;
    public IDColumn: string = "";
    public NameColumn: string = "";
    public Columns: string[] = [];
    public Contents: { [key: string]: any }[] = [];
    public LinkedListValues: { [key: string]: { [key: string]: any }[] } = {};
}

export class ListFolder {
    public Name: string = "";
    public Lists: api.ListInfo[] = [];
    public Expanded: boolean = false;
}

export class ActivityNames {
    public static discriminatorToLabel(discriminator: string | null | undefined): string {
        if (discriminator == null)
            return "Flowchart_Unknown";

        switch (discriminator.toLowerCase()) {
            case "dataentryactivity":
                return "ToolBox_Data_Input";
            case "delayprocessactivity":
                return "ToolBox_Delay";
            case "modeldataexportprocessactivity":
                return "ToolBox_Model_Data_Export";
            case "fileexportprocessactivity":
                return "ToolBox_File_Export";
            case "fileimportprocessactivity":
                return "ToolBox_File_Import";
            case "ifprocessactivity":
                return "ToolBox_Decision";
            case "logmessageprocessactivity":
                return "ToolBox_Log_Message";
            case "noteprocessactivity":
                return "ToolBox_Note";
            case "reportingactivity":
                return "ToolBox_Reporting";
            case "reviewactivity":
                return "ToolBox_Review";
            case "scriptprocessactivity":
                return "ToolBox_Script";
            case "sendemailprocessactivity":
                return "ToolBox_Send_Email";
            case "slackmessageprocessactivity":
                return "ToolBox_Send_Message";
            case "sqlserverstoredprocedureprocessactivity":
                return "ToolBox_Calculation";
            case "startprocessactivity":
                return "ToolBox_Start_Process";
            case "stopprocessactivity":
                return "ToolBox_Abort_Process";
            case "subprocessprocessactivity":
                return "ToolBox_Subprocess";
            case "todoprocessactivity":
                return "ToolBox_To_Do";
            case "venaexportprocessactivity":
                return "ToolBox_Vena_Export";
            case "venaimportprocessactivity":
                return "ToolBox_Vena_Import";
            case "venaimportprocessactivity":
                return "ToolBox_Vena_Import";
            case "azuredatafactorypipelineactivity":
                return "ToolBox_Azure_DF_Pipeline";
            case "runreportbookprocessactivity":
                return "ToolBox_Run_ReportBook";
            case "publishmodeltoanalysisservicesactivity":
                return "ToolBox_Refresh_SSAS_Model";
            case "publishmodeltohanaactivity":
                return "ToolBox_Publish_Hana_Model";
            case "adotriggeractivity":
                return "ToolBox_ADO_Trigger";
            case "adoimportactivity":
                return "ToolBox_ADO_Import";
            case "stagingtableimportprocessactivity":
                return "ToolBox_Staging_Table_Import";
            case "archivexl3reportactivity":
                return "ToolBox_Archive_XL3_Report";
            case "runworkflowprocessactivity":
                return "ToolBox_Run_Workflow";
            case "lockprocessactivity":
                return "ToolBox_Lock";
            case "foreachprocessactivity":
                return "ToolBox_Foreach";
            case "automaticfileimportprocessactivity":
                return "ToolBox_AutomaticFileImport";
            case "httprequestprocessactivity":
                return "ToolBox_HttpRequest";
            case "connectorprocessactivity":
                return "ToolBox_Connector";
            case "automatichierarchysync":
                return "ToolBox_AutomaticHierarchySync";
            default:
                {
                    console.log(discriminator.toLowerCase());
                    return "Flowchart_Unknown";
                }
        }
    }

    public static activityTypeToLabel(activityType: string | null | undefined): string {
        if (activityType == null)
            return "Flowchart_Unknown";

        switch (activityType.toLowerCase()) {
            case "datainput":
                return "ToolBox_Data_Input";
            case "delay":
                return "ToolBox_Delay";
            case "modeldataexport":
                return "ToolBox_Model_Data_Export";
            case "fileexport":
                return "ToolBox_File_Export";
            case "fileimport":
                return "ToolBox_File_Import";
            case "if":
                return "ToolBox_Decision";
            case "log":
                return "ToolBox_Log_Message";
            case "note":
                return "ToolBox_Note";
            case "reporting":
                return "ToolBox_Reporting";
            case "review":
                return "ToolBox_Review";
            case "script":
                return "ToolBox_Script";
            case "email":
                return "ToolBox_Send_Email";
            case "message":
                return "ToolBox_Send_Message";
            case "sqlsp":
                return "ToolBox_Stored_Procedure";
            case "start":
                return "ToolBox_Start_Process";
            case "stop":
                return "ToolBox_Abort_Process";
            case "subprocess":
                return "ToolBox_Subprocess";
            case "todo":
                return "ToolBox_To_Do";
            case "venaexport":
                return "ToolBox_Vena_Export";
            case "venaimport":
                return "ToolBox_Vena_Import";
            case "azuredatafactorypipelineactivity":
                return "ToolBox_Azure_DF_Pipeline";
            case "runreportbookprocessactivity":
                return "ToolBox_Run_ReportBook";
            case "publishmodeltoanalysisservices":
                return "ToolBox_Refresh_SSAS_Model";
            case "publishmodeltohana":
                return "ToolBox_Publish_Hana_Model";
            case "adotrigger":
                return "ToolBox_ADO_Trigger";
            case "adoimport":
                return "ToolBox_ADO_Import";
            case "archivexl3reportactivity":
                return "ToolBox_Archive_XL3_Report";
            case "runworkflowprocessactivity":
                return "ToolBox_Run_Workflow";
            case "lockprocessactivity":
                return "ToolBox_Lock";
            case "foreachprocessactivity":
                return "ToolBox_Foreach";
            case "automaticfileimportprocessactivity":
                return "ToolBox_AutomaticFileImport";
            case "automaticfileimportprocessactivity":
                return "ToolBox_AutomaticFileImport";
            case "automaticfileimportprocessactivity":
                return "ToolBox_AutomaticFileImport";
            case "httprequestprocessactivity":
                return "ToolBox_HttpRequest";
            case "connectorprocessactivity":
                return "ToolBox_Connector";
            case "automatichierarchysync":
                return "ToolBox_AutomaticHierarchySync";
            default:
                {
                    console.log(activityType);
                    return "Flowchart_Unknown";
                }
        }
    }

    //public static activityTypeToDiscriminator(activityType: string | null | undefined): string {
    //    if (activityType == null)
    //        return "Unknown";

    //    switch (activityType.toLowerCase()) {
    //        case "datainput":
    //            return "DataEntryActivity";
    //        case "delay":
    //            return "DelayProcessActivity";
    //        case "modeldataexport":
    //            return "ModelDataExportProcessActivity";
    //        case "fileexport":
    //            return "FileExportProcessActivity";
    //        case "fileimport":
    //            return "FileImportProcessActivity";
    //        case "if":
    //            return "IfProcessActivity";
    //        case "log":
    //            return "LogMessageProcessActivity";
    //        case "note":
    //            return "NoteProcessActivity";
    //        case "reporting":
    //            return "ReportingActivity";
    //        case "review":
    //            return "ReviewActivity";
    //        case "script":
    //            return "ScriptProcessActivity";
    //        case "email":
    //            return "SendEmailProcessActivity";
    //        case "message":
    //            return "SlackMessageProcessActivity";
    //        case "sqlsp":
    //            return "SqlServerStoredProcedureProcessActivity";
    //        case "start":
    //            return "StartProcessActivity";
    //        case "stop":
    //            return "StopProcessActivity";
    //        case "subprocess":
    //            return "SubProcessProcessActivity";
    //        case "todo":
    //            return "ToDoProcessActivity";
    //        case "venaexport":
    //            return "VenaExportProcessActivity";
    //        case "venaimport":
    //            return "VenaImportProcessActivity";
    //        case "azuredatafactorypipelineactivity":
    //            return "azuredatafactorypipelineactivity";
    //        case "runreportbookprocessactivity":
    //            return "RunReportBookProcessActivity"
    //        case "stagingtableimportprocessactivity":
    //            return "stagingtableimportprocessactivity";
    //        case "archivexl3reportactivity":
    //            return "archivexl3reportactivity";
    //        case "runworkflowprocessactivity":
    //            return "runworkflowprocessactivity";
    //        case "lockprocessactivity":
    //            return "lockprocessactivity";
    //        case "foreachprocessactivity":
    //            return "foreachprocessactivity";
    //        case "automaticfileimportprocessactivity":
    //            return "automaticfileimportprocessactivity";
    //        case "httprequestprocessactivity":
    //            return "httprequestprocessactivity";
    //        case "connectorprocessactivity":
    //            return "connectorprocessactivity";
    //        case "automatichierarchysync":
    //            return "automatichierarchysync";
    //        default:
    //            return "Unknown";
    //    }
    //}

    public static activityInstanceToLabel(activity: api.BaseProcessActivity | undefined | null): string {
        if (activity == null)
            return "";

        if (activity instanceof (api.DataEntryActivity))
            return "ToolBox_Data_Input";
        if (activity instanceof (api.DelayProcessActivity))
            return "ToolBox_Delay";
        if (activity instanceof (api.SendEmailProcessActivity))
            return "ToolBox_Send_Email";
        if (activity instanceof (api.ModelDataExportProcessActivity))
            return "ToolBox_Model_Data_Export";
        if (activity instanceof (api.FileExportProcessActivity))
            return "ToolBox_File_Export";
        if (activity instanceof (api.FileImportProcessActivity))
            return "ToolBox_File_Import";
        if (activity instanceof (api.IfProcessActivity))
            return "ToolBox_Decision";
        if (activity instanceof (api.LogMessageProcessActivity))
            return "ToolBox_Log_Message";
        if (activity instanceof (api.NoteProcessActivity))
            return "ToolBox_Note";
        if (activity instanceof (api.ReportingActivity))
            return "ToolBox_Reporting";
        if (activity instanceof (api.ReviewActivity))
            return "ToolBox_Review";
        if (activity instanceof (api.ScriptProcessActivity))
            return "ToolBox_Script";
        if (activity instanceof (api.SlackMessageProcessActivity))
            return "ToolBox_Send_Message";
        if (activity instanceof (api.SqlServerStoredProcedureProcessActivity))
            return "ToolBox_Calculation";
        if (activity instanceof (api.StartProcessActivity))
            return "Flowchart_Start";
        if (activity instanceof (api.StopProcessActivity))
            return "ToolBox_Abort";
        if (activity instanceof (api.LockProcessActivity))
            return "ToolBox_Lock";
        if (activity instanceof (api.ForeachProcessActivity))
            return "ToolBox_Foreach";
        if (activity instanceof (api.SubProcessProcessActivity))
            return "ToolBox_Subprocess";
        if (activity instanceof (api.ToDoProcessActivity))
            return "ToolBox_To_Do";
        if (activity instanceof (api.VenaExportProcessActivity))
            return "ToolBox_Vena_Export";
        if (activity instanceof (api.VenaImportProcessActivity))
            return "ToolBox_Vena_Import";
        if (activity instanceof (api.AzureDataFactoryPipelineActivity))
            return "ToolBox_Azure_DF_Pipeline";
        if (activity instanceof (api.RunReportBookProcessActivity))
            return "ToolBox_Run_ReportBook";
        if (activity instanceof (api.PublishModelToAnalysisServicesActivity))
            return "ToolBox_Refresh_SSAS_Model";
        if (activity instanceof (api.PublishModelToHanaActivity))
            return "ToolBox_Publish_Hana_Model";
        if (activity instanceof (api.ADOTriggerActivity))
            return "ToolBox_ADO_Trigger";
        if (activity instanceof (api.ADOImportActivity))
            return "ToolBox_ADO_Import";
        if (activity instanceof (api.StagingTableImportProcessActivity))
            return "ToolBox_Staging_Table_Import";
        if (activity instanceof (api.ArchiveXL3ReportActivity))
            return "ToolBox_Archive_XL3_Report";
        if (activity instanceof (api.RunWorkflowProcessActivity))
            return "ToolBox_Run_Workflow";
        if (activity instanceof (api.AutomaticFileImportProcessActivity))
            return "ToolBox_AutomaticFileImport";
        if (activity instanceof (api.ConnectorProcessActivity))
            return "ToolBox_Connector";
        if (activity instanceof (api.HttpRequestProcessActivity))
            return "ToolBox_HttpRequest";
        if (activity instanceof (api.AutomaticHierarchySyncProcessActivity))
            return "ToolBox_AutomaticHierarchySync";

        return "";
    }

    public static activityInstanceToDiscriminator(activity: api.BaseProcessActivity | undefined | null): string {
        if (activity instanceof (api.DataEntryActivity))
            return "DataEntryActivity";
        if (activity instanceof (api.DelayProcessActivity))
            return "DelayProcessActivity";
        if (activity instanceof (api.SendEmailProcessActivity))
            return "SendEmailProcessActivity";
        if (activity instanceof (api.ModelDataExportProcessActivity))
            return "ModelDataExportProcessActivity";
        if (activity instanceof (api.FileExportProcessActivity))
            return "FileExportProcessActivity";
        if (activity instanceof (api.FileImportProcessActivity))
            return "FileImportProcessActivity";
        if (activity instanceof (api.IfProcessActivity))
            return "IfProcessActivity";
        if (activity instanceof (api.LogMessageProcessActivity))
            return "LogMessageProcessActivity";
        if (activity instanceof (api.NoteProcessActivity))
            return "NoteProcessActivity";
        if (activity instanceof (api.ReportingActivity))
            return "ReportingActivity";
        if (activity instanceof (api.ReviewActivity))
            return "ReviewActivity";
        if (activity instanceof (api.ScriptProcessActivity))
            return "ScriptProcessActivity";
        if (activity instanceof (api.SlackMessageProcessActivity))
            return "SlackMessageProcessActivity";
        if (activity instanceof (api.SqlServerStoredProcedureProcessActivity))
            return "SqlServerStoredProcedureProcessActivity";
        if (activity instanceof (api.StartProcessActivity))
            return "StartProcessActivity";
        if (activity instanceof (api.StopProcessActivity))
            return "StopProcessActivity";
        if (activity instanceof (api.LockProcessActivity))
            return "LockProcessActivity";
        if (activity instanceof (api.ForeachProcessActivity))
            return "ForeachProcessActivity";
        if (activity instanceof (api.SubProcessProcessActivity))
            return "SubProcessProcessActivity";
        if (activity instanceof (api.ToDoProcessActivity))
            return "ToDoProcessActivity";
        if (activity instanceof (api.VenaExportProcessActivity))
            return "VenaExportProcessActivity";
        if (activity instanceof (api.VenaImportProcessActivity))
            return "VenaImportProcessActivity";
        if (activity instanceof (api.AzureDataFactoryPipelineActivity))
            return "AzureDataFactoryPipelineActivity";
        if (activity instanceof (api.RunReportBookProcessActivity))
            return "RunReportBookProcessActivity";
        if (activity instanceof (api.PublishModelToAnalysisServicesActivity))
            return "PublishModelToAnalysisServicesActivity";
        if (activity instanceof (api.PublishModelToHanaActivity))
            return "PublishModelToHanaActivity";
        if (activity instanceof (api.ADOTriggerActivity))
            return "ADOTriggerActivity";
        if (activity instanceof (api.ADOImportActivity))
            return "ADOImportActivity";
        if (activity instanceof (api.StagingTableImportProcessActivity))
            return "StagingTableImportProcessActivity";
        if (activity instanceof (api.ArchiveXL3ReportActivity))
            return "ArchiveXL3ReportActivity";
        if (activity instanceof (api.RunWorkflowProcessActivity))
            return "RunWorkflowProcessActivity";
        if (activity instanceof (api.AutomaticFileImportProcessActivity))
            return "AutomaticFileImportProcessActivity";
        if (activity instanceof (api.ConnectorProcessActivity))
            return "ConnectorProcessActivity";
        if (activity instanceof (api.HttpRequestProcessActivity))
            return "HttpRequestProcessActivity";
        if (activity instanceof (api.AutomaticHierarchySyncProcessActivity))
            return "AutomaticHierarchySyncProcessActivity";

        return "Unknown";
    }
}

export class TreeParameterNodeDefinition {
    //IsExpanded: boolean = false;
    Value: api.FormParameterValue;
    Display: string | null;
    Parent: TreeParameterNodeDefinition | null = null;
    Children: TreeParameterNodeDefinition[] = [];

    constructor(val: api.FormParameterValue, display: string | null = null) {
        this.Value = val;
        this.Value.isLeaf = true;  // guilty until proven innocent (during the hierarchy sorting)
        this.Display = display;
    }

    get IsLeaf(): boolean {
        return this.Value.isLeaf;
    }

    get IsRoot(): boolean {
        return this.Parent == null;
    }

    get Label(): string {
        if (this.Display == "Key")
            return this.Value.key!;
        else if (this.Display == "Name")
            return this.Value.name!;
        else if (this.Display == "Caption")
            return this.Value.caption!;
        else
            return "";
    }
}

export class ReportBookItem extends api.ReportBookItem {
    get definition(): api.BookDefinition {
        var entries: api.BookForm[] | null = null;
        if (this.entries) {
            entries = [];
            for (var e of this.entries) {
                let fso = e.fileSystemObject!;
                var fullPath = fso.path + "/" + fso.name;
                while (fullPath.startsWith("//")) // fix for parent being the root
                    fullPath = fullPath.replace("//", "/");

                entries.push(new api.BookForm({
                    description: e.description,
                    path: fullPath,
                    parameters: e.parameters && e.parameters.parameters ? this.flattenParameters(e.parameters.parameters) : [],
                    tabNames: e.tabNames
                }));
            }
        }

        return new api.BookDefinition({
            name: this.name,
            password: this.password,
            output: this.output,
            compilation: this.compilation,
            destination: this.destination,
            entries: entries,
            parameters: this.parameters ? this.parameters.map(p => new api.BookParameter(p.bookParameter!)) : null
        });
    }

    private flattenParameters(params: api.FormParameter[]): api.KeyValuePairOfStringAndString[] {
        var newParams: api.KeyValuePairOfStringAndString[] = [];
        for (var p of params) {
            if (p && p.name) {
                newParams.push(new api.KeyValuePairOfStringAndString({
                    key: p.name,
                    value: p.currentValue && p.currentValue.key ? p.currentValue.key : ""
                }));
            }
        }
        return newParams;
    }

    get activityFile(): api.ActivityFile {
        return new api.ActivityFile({
            name: this.fileSystemObject!.name,
            path: this.fileSystemObject!.path,
            description: "",
            objectType: api.FileSystemType.ReportBook,
            newParameters: new api.FormParameterList({
                formId: this.fileSystemObject!.id,
                parameters: this.parameters ? this.parameters.map(p => {
                    // This nonsense is brought to you by: typescript
                    // If you don't include all of this "type forcing", then .toJSON() fails (as some of the objects have lost their type along the way)
                    var newParameter = new api.FormParameter(p.formParameter!);
                    newParameter.values = newParameter.values ? newParameter.values.map(v => new api.FormParameterValue(v)) : [];
                    newParameter.currentValue = newParameter.currentValue ? new api.FormParameterValue(newParameter.currentValue) : null;
                    return newParameter;
                }) : []
            }),
            outputOverride: this.output as any as api.BookOutput,
            destinationOverride: this.destination
        });
    }

    get definitionAndParameters(): api.BookDefinitionAndCurrentParameters {
        return new api.BookDefinitionAndCurrentParameters({
            bookDefinition: this.definition,
            currentParameters: this.parameters ? this.parameters.map(p => new api.FormParameterCurrentValue({
                name: p.formParameter ? p.formParameter.name : null,
                currentValue: p.formParameter ? p.formParameter.currentValue : null,
                isPinned: p.formParameter ? p.formParameter.isPinned : false
            })) : []
        });
    }
}

export class ToastNotificationLogItem extends api.ToastNotification {
    deepMessage?: string;
    dateTime: Date = new Date();
    trackingNumber: string;
    stack?: string;

    constructor(data: IToastNotificationLogItem) {
        super(data);
        this.trackingNumber = "";
        this.deepMessage = data.deepMessage;
        this.dateTime = new Date();
        this.stack = data.stack;
    }
}

export interface IToastNotificationLogItem extends api.IToastNotification {
    deepMessage?: string;
    dateTime: Date;
    stack?: string;
}

declare global {
    interface File {
        toBase64(): Promise<string>;
    }

    interface String {
        toUint8Array(): Uint8Array;
        isWhitespace(): boolean;
        containsSpecialCharacters(): boolean;
        trimCharacter(char: string): string;
    }
}

File.prototype.toBase64 = function (): Promise<string> {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
            var result = reader.result as string;
            var base64 = result.replace(/^data:.*;base64,/, "");
            resolve(base64);
        }
        reader.readAsDataURL(this);
    })
}

String.prototype.toUint8Array = function (this: string): Uint8Array {
    return Uint8Array.from(atob(this).split('').map(char => char.charCodeAt(0)));
}

String.prototype.isWhitespace = function (this: string): boolean {
    return this.match(/^\s*$/) !== null;
}

String.prototype.containsSpecialCharacters = function (this: string): boolean {
    return this.match(/[^a-zA-Z0-9 _]/) !== null;
}

String.prototype.trimCharacter = function (this: string, char: string): string {
    let start = 0;
    let end = this.length;

    while (start < end && this[start] == char)
        ++start;

    while (end > start && this[end - 1] == char)
        --end;

    return this.substring(start, end);
}

function FixNodes(data: any) {
    for (var node of data.nodes) {
        // This weird addition is necessary when creating new a new InteractiveProcess based on the object "data".
        // Why? Because:
        // process = new api.InteractiveProcess(data) does not recursively type the properties of object,
        //      meaning process.nodes[0].data.activity.subProcess (for example) is not typed properly and therefore does not have method .toJSON()
        // process.init(data) DOES recursively type the properties of the object,
        //      but for some reason objects that map to api.BaseProcessActivity require the property "descriminator"... which doesn't exist in the class api.BaseProcessActivity.
        node.activity.discriminator = ActivityNames.activityInstanceToDiscriminator(node.activity);

        // These properties sometimes have the wrong case.
        if (node.activity.owner)
            node.activity.Owner = node.activity.owner;
        if (node.activity.supervisor)
            node.activity.Supervisor = node.activity.supervisor;
        if (node.activity.contributor)
            node.activity.Contributor = node.activity.contributor;

        // Fix recursively
        if (node.activity instanceof (api.SubProcessProcessActivity)) {
            FixNodes(node.activity.subProcess);
        }
    }
}

export function GetInteractiveProcess(data: any): api.InteractiveProcess {
    FixNodes(data);
    var process = new api.InteractiveProcess();
    process.init(data);
    return process;
}

export class DateTimeHelper {
    public static ParseDueDateForProcessEditor(localizer: Localizer, formula: string): string {
        // if formula is null/empty => returns empty string
        // if formula is a due date formula:
        //      that starts with a space (indicating Absolulte Date, in UTC) => returns date, in local time zone, formatted as YYYY-mm-dd hh:mm
        //      that starts with another indicator, e.g. "=" indicating From Activity Start => returns with the format 2 d 6 h 30 min (from activity start)

        if (formula && formula != null && formula != "") {
            var sgn = formula.substring(0, 1);
            var sgnStr = "";
            var number = formula.substring(1);
            switch (sgn) {
                case '=':
                    sgnStr = "(" + localizer.Localize("DueDateEditor_From_activity_start_time") + ")";
                    break;
                case '!':
                    sgnStr = "(" + localizer.Localize("DueDateEditor_From_activity_start_time_business_days_only") + ")";
                    break;
                case '+':
                    sgnStr = "(" + localizer.Localize("DueDateEditor_From_process_start_time") + ")";
                    break;
                case '@':
                    sgnStr = "(" + localizer.Localize("DueDateEditor_From_process_start_time_business_days_only") + ")";
                    break;
                case '-':
                    sgnStr = "(" + localizer.Localize("DueDateEditor_Before_process_end_time") + ")";
                    break;
                case '#':
                    sgnStr = "(" + localizer.Localize("DueDateEditor_Before_process_end_time_business_days_only") + ")";
                    break;
                default:
                    var local = this.LocalTime(number);
                    return local.toISODate() + " " + local.toISOTime({ suppressSeconds: true, suppressMilliseconds: true, includeOffset: false });
            }

            var parsed = Number.parseFloat(number);
            var d = Math.floor(parsed);
            if (Number.isNaN(d))
                d = 0;
            var h = Math.floor(parsed % 1 * 24);
            if (Number.isNaN(h))
                h = 0;
            var m = Math.round(parsed % 1 * 24 % 1 * 60);
            if (Number.isNaN(m))
                m = 0;

            number = d + " d ";
            if (h != 0 || m != 0)
                number += h + " h " + m + " min ";

            number += sgnStr;
            return number;
        }
        else {
            return "";
        }
    }

    public static LocalTime(utcString: string): luxon.DateTime {
        var utc = luxon.DateTime.fromISO(utcString, { zone: "utc" });
        return utc.toLocal();
    }

    public static UTCTime(localString: string): luxon.DateTime {
        var local = luxon.DateTime.fromISO(localString);
        return local.toUTC();
    }
}

export class StagingTableField extends api.StagingTableField {
    public isNew: boolean = true;

    get displayOnly(): boolean {
        return !this.isNew && this.name!.startsWith("_");
    }
    get hasErrors(): boolean {
        return this.nameUniquenessViolated || this.nameValidationError != null;
    }

    nameUniquenessViolated: boolean = false;
    get nameValidationError(): string | null {
        var name = this.name;
        if (!name || name == "")
            return "StagingTableDetails_Please_enter_a_name_for_this_field";
        else if (name.includes(" ") || name.containsSpecialCharacters())
            return "StagingTableDetails_Field_names_can_only_include_letters_AZ_numbers_09_and_underscores";
        else if (this.isNew && name.startsWith("_"))
            return "StagingTableDetails_You_may_not_give_a_field_a_name_starting_with_an_underscore";
        else
            return null;
    }
}

export class DashboardSection extends api.DashboardSection {
    public isChecked: boolean = false;
    public get isNew(): boolean {
        return this.id === 0;
    }

    public static New(): DashboardSection {
        // we assume any homepage with id = 0 is new
        // because id column in App_DashboardSections is IDENTITY(1, 1): auto-incrementing starting at 1
        return new DashboardSection({
            id: 0,
            name: "",
            userName: "",
            orderNo: 1
        });
    }

    public toJSON(data?: any) {
        data = super.toJSON(data);
        data["isChecked"] = this.isChecked !== undefined ? this.isChecked : <any>null;
        return data;
    }
}

export class JournalColumn {
    show: boolean = true;
    selected: boolean = false;
    dimensionName: string = '';
    dimensionValue: string = '';
    constantValue?: string | null = '';
    defaultValue?: string | null = '';
    isInitialQuery?: boolean = false;
    defaultQueryValue?: string | null = '';
    isHidden?: boolean | false;
}

//TODO should probably extend from api.JournalHeader
export class JournalHeader {
    id: number = -1;
    groupId: number = -1;
    readOnly: boolean = false;
    jeTag?: string | null = '';
    description?: string | null = '';
    recurringFromKey?: string | null = null;
    recurringToKey?: string | null = null;
    status: string = 'Unposted';
    details: JournalDetail[] = [];
    selected: boolean = false;
    updated: boolean = false;
    isNew: boolean = false;
    isRecurring: boolean = false;
    isValid: boolean = false;
    validationErrors: number = -1;
    validationWarnings: number = -1;
    dimensionValues: JournalFilter[] = [];
    attachments?: api.JournalAttachment[] | null = [];
}

//TODO should probably extend from api.JournalDetail
export class JournalDetail {
    id: number = -1;
    header_Id: number = -1;
    readOnly: boolean = false;
    credit: number | string = '';
    debit: number | string = '';
    selected: boolean = false;
    updated: boolean = false;
    isNew: boolean = false;
    isValid: boolean = true;
    dimensionValues: JournalFilter[] = [];
}

export class JournalFilter {
    dimensionName: string | null | undefined = null;
    dimensionMemberKey: string | null | undefined = null;
    dimensionMemberName: string | null | undefined = null;
    dimensionMemberCaption: string | null | undefined = null;
}

export class JournalHelper {
    public static ColapseDimensions = function (header: JournalHeader) {
        for (var i = 0; i < header.dimensionValues.length; i++) {
            for (var j = i + 1; j < header.dimensionValues.length; j++) {
                if (header.dimensionValues[i].dimensionName == header.dimensionValues[j].dimensionName) {
                    header.dimensionValues[i].dimensionMemberName += ',' + header.dimensionValues[j].dimensionMemberName;
                    header.dimensionValues.splice(j, 1);
                    j--;
                }
            }
        }
    }

    public static ExpandDimensions = function (header: JournalHeader) {
    }
}

export class HierarchySubset {
    dimensionName: string = '';
    dimensionMembers: api.MemberInfo[] = [];
}
