<template>
    <div>
        <p>
            <TitleBar :title="Localizer.Localize('ChooseTenant_Choose_Tenant')+' ('+UserName+')'">
                <template v-slot:right-group>
                    <input v-if="TenantsNotFiltered!=null && TenantsNotFiltered.length>5" type="text" v-model="filter" style="max-width:400px; float:right" placeholder="Filter" />
                </template>
            </TitleBar>
        </p>
        <div :style="isChristmas?'margin:10px 10px 10px 30px':'margin:10px 10px 10px 10px' ">
            <p style="margin-left:10px; display: inline-flex; align-items: flex-end;">
                <Checkbox v-model="filterTenants" /> &nbsp;
                {{Localizer.FormatLocalize("ChooseTenant_Display_only_0_tenants", currentEnvironment)}}
            </p>
            <br />

            <div v-if="UserDoesNotHaveTenants">
                <Empty>{{Localizer.Localize("ChooseTenant_You_don_t_have_access_to_any_tenants")}}({{UserName}}).</Empty>
                <div class="btnViewer btnViewerDefault" @click="Logout_Click">{{Localizer.Localize("ChooseTenant_LOGOUT")}}</div>
            </div>

            <transition-group name="fso" tag="span">
                <div v-for="(Tenant, i) in Tenants"
                     class="tenant_btn"
                     @click="Tenant_Click(Tenant, $event)"
                     :key="Tenant.name"
                     :title="Tenant.name"
                     :style="{'--i':i/Tenants.length}">
                    <div v-if="GetImage(Tenant.name)!=null" style="width:0px; height:0px; position:absolute; margin-top: -200px; margin-left: -200px; overflow:visible">
                        <img :src="GetImage(Tenant.name)" style="width:200px; height:200px;" />
                    </div>
                    <img src="../../public/icons/santahat.png" style="z-index:1; position: absolute; margin-top: -98px; margin-left: -172px;" v-if="isChristmas" />
                    <div class="bgColor" :style="[Tenant.bgColor ? `background-color: ${Tenant.bgColor}` : '']" />
                    <span style="z-index: 1;">{{FormatTenantName(Tenant.name, Tenant.caption)}}</span>
                    <span style="z-index: 1;" class="env">
                        ({{Tenant.environment}})
                    </span>

                </div>
            </transition-group>

            <div class="tenant_btn" style="background-color: var(--accent-color3);" v-if="!LoadingTenants"
                 @click="Logout_Click">
                <div class="bgColor" />
                <span style="z-index: 1;">Sign Out</span>
            </div>

            <div class="tenant_btn" style="background-color: var(--accent-color3);" v-if="!LoadingTenants"
                 @click="SwitchUser_Click">
                <div class="bgColor" />
                <span style="z-index: 1;">Sign in as a different user</span>
            </div>

            <RightPanel v-if="GenerateAiImageRightPanel" @close="GenerateAiImageRightPanel=false">
                <template v-slot:header>
                    <span class="title">Set Image({{SelectedTenant}})</span>
                </template>
                <template v-slot:body>
                    <div class="bodyContents">
                        <label>Generate:</label>
                        <textarea rows="3" type="text" v-model="AiPrompt" />
                    </div>
                    <div v-if="IsBusy">
                        <Spinner />
                    </div>
                    <div v-if="!IsBusy" style="margin:0 auto;">
                        <img v-if="AiUrl!=''" :src="AiUrl" style="width:128px; height:128px; display:block;" />
                    </div>
                    <a v-if="!IsBusy && AiUrl!=''" @click="UseAiImage()" href="#">Use this image</a>
                </template>
                <template v-slot:footer>
                    <div class="panelButtons">
                        <div class="btnViewer" @click="GenerateAiImageRightPanel=false">{{Localizer.Localize('Cancel')}}</div>
                        <div class="btnViewer btnViewerDefault" v-if="AiPrompt!='' && !IsBusy" @click="GenerateAiImage()">{{Localizer.Localize('GENERATE')}}</div>
                    </div>
                    <br />
                </template>
            </RightPanel>
        </div>
    </div>
</template>
<script lang="ts">
    require('@/assets/portal.css');
    import { Component, Prop, Vue } from 'vue-facing-decorator';
    import { AuthClient } from "../api/ApiClientBase";
    import * as api from "../api/ApiClient";
    import { Localizer } from "../api/Localizer";
    import TitleBar from "./helpers/TitleBar.vue";
    import Spinner from "./helpers/Spinner.vue";
    import RightPanel from "./helpers/RightPanel.vue";
    import Empty from "./helpers/Empty.vue";
    import Checkbox from "./helpers/Checkbox.vue";
    import Confirm2 from "./helpers/Confirm2.vue";
    @Component({ components: { Spinner, Empty, Checkbox, Confirm2, RightPanel, TitleBar, Localizer } })
    export default class ChooseTenant extends Vue {
        private Localizer = new Localizer(this.$store);
        private currentEnvironment: string = ''
        private filterTenants: boolean = false;
        private isChristmas = false;
        private filter: string = "";

        get TenantsNotFiltered(): api.Tenant[] | null {
            this.isChristmas = (this.currentEnvironment == "Local" || this.currentEnvironment == "Dev") && new Date().getMonth() == 11;
            var tenants = this.$store.state.security.Tenants as (api.Tenant[] | null);
            if (this.Tenant == null && tenants != null && tenants.length == 1)
                this.$store.dispatch("security/SelectTenant", tenants[0]);

            var result: api.Tenant[] | null = [];
            if (tenants != null) {
                result = tenants!.filter(t => { return !this.filterTenants || t.environment?.toLowerCase() == this.currentEnvironment.toLowerCase() });
            }
            else
                result = tenants;

            return result;
        }

        get Tenants(): api.Tenant[] | null {

            var result = this.TenantsNotFiltered;

            if (result != null && this.filter != null && this.filter.length > 0)
                result = result!.filter(t => {
                    var ok = t.name!.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0;
                    if (!ok && t.caption != null)
                        ok = this.Localizer.ParseLocalizedString(t.caption)!.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0;
                    return ok;
                });

            return result;
        }


        get Tenant(): api.Tenant | null { return this.$store.state.security.Tenant; }
        get LoadingTenants(): boolean { return this.Tenants === null }
        get UserDoesNotHaveTenants(): boolean { return this.Tenants != null && this.Tenants.length === 0 }
        get UserName(): string { return this.$store.state.security.UserName; }
        private async Logout_Click(): Promise<void> {
            this.$store.dispatch("security/LogOut");
        }
        private async SwitchUser_Click(): Promise<void> {
            this.$store.dispatch("security/SwitchUser");
        }
        private async Tenant_Click(tenant: api.Tenant, e: MouseEvent): Promise<void> {
            if (e.ctrlKey) {
                this.SelectedTenant = tenant.name!;
                this.GenerateAiImageRightPanel = true;
            }
            else if (e.shiftKey) {
                this.$store.dispatch("security/SelectTenant", tenant);
            }
            else {
                if (tenant.environment != this.currentEnvironment && tenant.portalUrl != null) {
                    document.location.assign(tenant.portalUrl + "?tenant=" + tenant.name);
                }
                else
                    this.$store.dispatch("security/SelectTenant", tenant);
            }
        }
        private async mounted() {
            this.Localizer = new Localizer(this.$store);
            this.currentEnvironment = AuthClient.configuration.$config?.environment || '';
            var auth_client = new AuthClient();
        }
        private FormatTenantName(name: string, caption: string) {
            if (caption != null)
                return this.Localizer.ParseLocalizedString(caption);
            name = name.replaceAll("_", " ");
            if (name.length < 10 || name.indexOf(' ') > 0)
                return name;
            else {
                return name.replace(/([a-z0-9])([A-Z])/g, '$1 $2')
            }
        }
        IsBusy: boolean = false;
        SelectedTenant = '';
        GenerateAiImageRightPanel: boolean = false;
        AiPrompt: string = "";
        AiUrl: string | null = "";
        private GetImage(tenantName: string): string | null {
            var tenantImage = TenantImage.TenantImages.find(t => t.TenantName == tenantName);
            if (tenantImage != null)
                return tenantImage.Image;
            else
                return null;
        }
        private UseAiImage() {
            var tenantImage = TenantImage.TenantImages.find(t => t.TenantName == this.SelectedTenant);
            if (tenantImage != null)
                tenantImage.Image = this.AiUrl!;
            else {
                tenantImage = new TenantImage(this.SelectedTenant, this.AiUrl!);
                TenantImage.TenantImages.push(tenantImage);
            }
            this.SelectedTenant = '';
            this.GenerateAiImageRightPanel = false;
        }
        private async GenerateAiImage() {
            this.IsBusy = true;
            try {
                const authClient = new AuthClient();
                await authClient.ensureToken();
                const client = new api.SecurityClient(authClient);
                this.AiUrl = "data:image/png;base64, " + await client.generateUserImage(this.AiPrompt);
            }
            catch (e) {
                this.AiUrl = "";
                await this.$store.dispatch("ShowToast", new api.ToastNotification({
                    message: this.Localizer.Localize('AiImage_generation_failed'),
                    type: api.ToastType.Info
                }));
            }
            finally {
                this.IsBusy = false;
            }
        }
    }
    class TenantImage {
        public TenantName: string;
        public Image: string;
        static TenantImages: TenantImage[] = [];
        constructor(tenantName: string, image: string) {
            this.TenantName = tenantName;
            this.Image = image;
        }
    }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .header-bar {
        border-bottom: 1px solid var(--thin-border);
    }

    .tenant_btn {
        position: relative;
        width: 200px;
        height: 200px;
        color: var(--accent-foreground);
        background-color: var(--accent-color);
        cursor: pointer;
        margin: 10px;
        padding: 10px;
        overflow: auto;
        float: left;
        justify-content: center; /* align horizontal */
        align-items: center; /* align vertical */
        text-align: center;
        font-size: 25px;
        display: flex;
        flex-direction: column;
        text-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    }

    .env {
        font-size: 15px;
        text-transform: capitalize;
    }

    .fso-leave-active {
    }

    .fso-enter-active {
        transition: all .3s ease;
        transition-delay: calc(0.500s * var(--i));
    }

    .fso-enter-from, .fso-leave-to {
        opacity: 0;
        transform: translateX(30px);
    }

    .bgColor {
        position: absolute;
        height: 20px;
        width: 100%;
        bottom: 0;
        z-index: 1;
        background-color: var(--accent-color-blue-midnight);
    }
</style>
