import App from './App.vue';
import AppJS from './AppJS.vue';
import router from './routing/Router'
import store from './store/GlobalStore'
import './registerServiceWorker'
import { AuthClient, Configuration } from "./api/ApiClientBase";
import * as ribbonFunctions from "../src/components/pages/excel/RibbonFunctions";
import { createApp } from 'vue'
import PortalVue from 'portal-vue';
import { JsPlumbToolkitVue2Plugin } from 'jsplumbtoolkit-vue2';
// @ts-ignore
import Toast from "vue-toastification";
import UiComponents from 'uicomponents';
// @ts-ignore
import cronLight from '@vue-js-cron/light';
// @ts-ignore
import Vue3Sanitize from "vue-3-sanitize";



const start = async function () {
    window.localStorage.removeItem("Excel");
    var response = await fetch(process.env.BASE_URL + "config.json");
    var json = await response.json();

    if (json.hideWhatsNew == "false")
        json.hideWhatsNew = false;
    if (json.hideWhatsNew == "true")
        json.hideWhatsNew = true;


    AuthClient.configuration.apiBaseUrl = json.apiBaseUrl;
    AuthClient.configuration.apiBaseUrl_dev = json.apiBaseUrl_dev;
    AuthClient.configuration.apiBaseUrl_anaplan = json.apiBaseUrl_anaplan;
    AuthClient.configuration.$config = json;

    var currentUrl = window.location.href;
    var hostname = new URL(currentUrl).hostname;
    if (hostname.endsWith("app.anaplan.com")) {
        AuthClient.isAnaplanLogin = true;
        AuthClient.configuration.apiBaseUrl = AuthClient.configuration.apiBaseUrl_anaplan;
        AuthClient.accessToken = "Anaplan";
    }

    document.title = AuthClient.configuration.$config?.portalTitle ?? "Fluence Portal";

    var isExcel = window.localStorage.getItem("Excel");
    if (document.URL.indexOf("fluencejs") > 0 || isExcel) {
        window.localStorage.setItem("Excel", "Excel");

        Configuration.environment = "officejs";

        var script = document.createElement('script');
        //script.src = "https://appsforoffice.microsoft.com/lib/1/hosted/office.js";
        script.src = "https://appsforoffice.microsoft.com/lib/beta/hosted/office.js";

        script.onload = function () {
            (window as any).Office.initialize = () => {
                const app = createApp(AppJS);

                app.use(PortalVue);
                app.use(store);
                app.use(router);
                app.use(Toast, {
                    transition: "Vue-Toastification__bounce",
                    maxToasts: 20,
                    newestOnTop: true
                });

                let defaults = Vue3Sanitize.defaults;
                defaults.allowedTags.push('img');
                defaults.allowedSchemes.push('data');
                const overridenOptions = JSON.parse(JSON.stringify(defaults));
                app.use(Vue3Sanitize, overridenOptions);

                router.isReady().then(() => app.mount('#app'));
            }
            function getGlobal() {
                return typeof self !== "undefined"
                    ? self
                    : typeof window !== "undefined"
                        ? window
                        : typeof global !== "undefined"
                            ? global
                            : undefined;
            }

            const g = getGlobal() as any;

            g.ShowTaskpane = ribbonFunctions.ShowTaskpane;
        };

        document.head.appendChild(script);
    }
    else {
        const app = createApp(App);

        app.use(PortalVue);
        app.use(store);
        app.use(router);
        app.use(cronLight);
        // @ts-ignore
        app.use(JsPlumbToolkitVue2Plugin)
        app.use(Toast, {
            transition: "Vue-Toastification__bounce",
            maxToasts: 20,
            newestOnTop: true
        });
        app.use(UiComponents);

        let defaults = Vue3Sanitize.defaults;
        defaults.allowedTags.push('img');
        defaults.allowedSchemes.push('data');
        defaults.allowedAttributes["img"] = ['src', 'srcset', 'alt', 'title', 'loading'];
        defaults.allowedClasses = {
            '*': ['whatsnewtable']
        }
        const overridenOptions = JSON.parse(JSON.stringify(defaults));
        app.use(Vue3Sanitize, overridenOptions);

        router.isReady().then(() => app.mount('#app'));
    }
};

start();


