import { GetterTree, MutationTree, Module } from 'vuex';
import { ExplorerNotificationsHandler } from "../api/ExplorerNotificationsHandler";
import { AuthClient } from "../api/ApiClientBase";
import * as api from "../api/ApiClient";
import * as ext from "../api/Extensions";
import Localizer from '../api/Localizer';

export interface IExplorerStore {
    Working: boolean;
    Root: api.FileSystemObjectContent | null;
    Favorites: string[] | null;
    SelectedFso: api.FileSystemObjectContent | null;
    SecondarySelectedFso: api.FileSystemObjectContent | null;
    PasteOperationDetails: ext.PasteOperation;
    PasteTargetFso: api.FileSystemObjectContent | null;
    NotificationHandler: ExplorerNotificationsHandler | null;
    InProgressLoadFileSystemOperation: Promise<api.FileSystemObjectContent> | null;
    NewFsoType: api.FileSystemType | null;
    FsoToRename: api.FileSystemObjectContent | null;
    OfficeReportToEdit: api.FileSystemObjectContent | null;
    FsoToEditSecurity: api.FileSystemObjectContent | null;
    FsoExpandedState: boolean[];
    SecondaryFsoExpandedState: boolean[];
    XL3DefaultServer: string | null;
    XL3DefaultStandaloneServer: string | null;
    SearchText: string;
    CurrentSearchResult: api.FileSystemObjectContent | null;
}

const ExplorerStore: Module<IExplorerStore, any> = {
    namespaced: true,
    state: {
        Working: false,
        Root: null,
        Favorites: null,
        SelectedFso: null,
        SecondarySelectedFso: null,
        PasteOperationDetails: new ext.PasteOperation(),
        PasteTargetFso: null,
        NotificationHandler: null,
        InProgressLoadFileSystemOperation: null,
        NewFsoType: null,
        FsoToRename: null,
        OfficeReportToEdit: null,
        FsoToEditSecurity: null,
        FsoExpandedState: [],
        SecondaryFsoExpandedState: [],
        XL3DefaultServer: null,
        XL3DefaultStandaloneServer: null,
        SearchText: "",
        CurrentSearchResult: null,
    },

    mutations: {
        SetWorking(state, val: boolean) {
            state.Working = val;
        },

        SetFileSystem(state, fs: api.FileSystemObjectContent | null) {
            state.Root = fs;
            if (fs === null)
                state.FsoExpandedState = [];
        },

        SetFavorites(state, val: string[] | null) {
            if (val != null)
                for (var i = 0; i < val.length; i++)
                    if (!val[i].startsWith('/'))
                        val[i] = '/' + val[i];
            state.Favorites = val;
        },

        SelectFso(state, fso: api.FileSystemObjectContent | null) {
            state.SelectedFso = fso;
        },

        SetPasteTargetFso(state, fso: api.FileSystemObjectContent | null) {
            state.PasteTargetFso = fso;
        },

        SelectSecondaryFso(state, fso: api.FileSystemObjectContent | null) {
            state.SecondarySelectedFso = fso;
        },

        SetPasteOperationDetails(state, details: ext.PasteOperation | null) {
            if (details == null)
                details = new ext.PasteOperation();
            state.PasteOperationDetails = details;
        },

        SetNotificationHandler(state, notificationHandler: ExplorerNotificationsHandler | null) {
            if (state.NotificationHandler != null)
                state.NotificationHandler.stop();
            state.NotificationHandler = notificationHandler;
            if (state.NotificationHandler != null)
                state.NotificationHandler.start();
        },

        SetInProgressLoadFileSystemOperation(state, value: Promise<api.FileSystemObjectContent> | null) {
            state.InProgressLoadFileSystemOperation = value;
        },

        SetNewFsoType(state, val: api.FileSystemType | null) {
            state.NewFsoType = val;
        },

        SetFsoToRename(state, val: api.FileSystemObjectContent | null) {
            state.FsoToRename = val;
        },

        SetOfficeReportToEdit(state, val: api.FileSystemObjectContent | null) {
            state.OfficeReportToEdit = val;
        },

        SetFsoToEditSecurity(state, val: api.FileSystemObjectContent | null) {
            state.FsoToEditSecurity = val;
        },

        SetFsoExpandedState(state, val: boolean[]) {
            state.FsoExpandedState = val;
        },

        SetSecondaryFsoExpandedState(state, val: boolean[]) {
            state.SecondaryFsoExpandedState = val;
        },

        SetXL3DefaultServer(state, val: string | null) {
            state.XL3DefaultServer = val;
        },

        SetXL3DefaultStandaloneServer(state, val: string | null) {
            state.XL3DefaultStandaloneServer = val;
        },

        SetSearchText(state, val: string) {
            state.SearchText = val;
        },

        SetCurrentSearchResult(state, val: api.FileSystemObjectContent) {
            state.CurrentSearchResult = val;
        }
    },

    actions: {
        async LoadFileSystem(context) {
            var internalCall = async function () {
                var auth_client = new AuthClient();
                await auth_client.ensureToken();
                var client = new api.FileSystemClient(auth_client);
                var fsPromise = client.getFile("", null, true);
                var favPromise = client.getMyFavorites();

                var fs = await fsPromise;
                context.commit("SetFileSystem", fs);
                if (fs != null && context.state.SelectedFso == null) {
                    context.commit("SelectFso", context.state.Root);
                    context.state.FsoExpandedState[fs.id] = true;
                }

                try {
                    var fav = await favPromise;
                    context.commit("SetFavorites", fav);
                }
                catch (err) {
                    context.commit("SetFavorites", []);
                    context.dispatch("ShowToastError", err);
                }

                context.commit("SetPasteOperationDetails", new ext.PasteOperation());
                context.commit("SetNotificationHandler", new ExplorerNotificationsHandler(context));
            }

            try {
                if (context.state.InProgressLoadFileSystemOperation) {
                    console.log("Load file system already in progress;");
                    await context.state.InProgressLoadFileSystemOperation;
                }
                else {
                    var promise = internalCall();
                    context.commit("SetInProgressLoadFileSystemOperation", promise);
                    await promise;
                }
            }
            finally {
                context.commit("SetInProgressLoadFileSystemOperation", null);
            }
        },

        async CreateFolder(context, folderName) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client = new api.FileSystemClient(auth_client);
            var fs = context.state.SelectedFso;
            if (fs != null) {
                var newFs = new api.FileSystemObject();
                newFs.name = folderName;
                newFs.objectType = api.FileSystemType.Folder;
                newFs.parentId = fs.id;
                if (fs == context.state.Root)
                    newFs.path = "/" + folderName;
                else
                    newFs.path = fs.path + "/" + fs.name + "/" + folderName
                while (newFs.path.startsWith('//')) // fix for parent being the root
                    newFs.path = newFs.path.replace("//", "/");
                newFs.id = 0;
                var notification = await client.post(newFs.path!, newFs, false);
                await context.state.NotificationHandler!.HandleNotification(notification);
            }
        },

        async CreatePowerBI(context, { reportName, definition }: { reportName: string, definition: api.PowerBIReportInfo }) {
            await context.dispatch("CreateNewFso", {
                name: reportName,
                type: api.FileSystemType.PowerBI,
                textContent: JSON.stringify(definition),
                binaryContent: null
            });
        },

        async CreateXL3(context, { reportName, reportId }: { reportName: string, reportId: string }) {
            await context.dispatch("CreateNewFso", {
                name: reportName,
                type: api.FileSystemType.XL3,
                textContent: reportId,
                binaryContent: null
            });
        },

        async CreateNewFso(context, { name, type, textContent, binaryContent }: { name: string, type: api.FileSystemType, textContent: string, binaryContent: string }) {
            var fso = context.state.SelectedFso;
            if (fso != null) {
                var newFso = new api.FileSystemObject();
                newFso.name = name;
                newFso.objectType = type;
                newFso.parentId = fso.id;

                newFso.path = fso.path + "/" + fso.name;
                while (newFso.path.startsWith('//')) // fix for parent being the root
                    newFso.path = newFso.path.replace("//", "/");
                var fullPath = newFso.path + "/" + name;
                while (fullPath.startsWith('//')) // fix for parent being the root
                    fullPath = fullPath.replace("//", "/");

                newFso.id = 0;  // a placeholder needed for JSONification... will be replaced with a new id on the server.
                newFso.textContent = textContent;
                newFso.binaryContent = binaryContent;

                var auth_client = new AuthClient();
                await auth_client.ensureToken();
                var client = new api.FileSystemClient(auth_client);
                var notification = await client.post(fullPath, newFso, false);
                await context.state.NotificationHandler!.HandleNotification(notification);

                if (type === api.FileSystemType.DocxReport || type === api.FileSystemType.PptxReport) {
                    newFso.id = notification.id!;
                    context.commit("SetOfficeReportToEdit", newFso);
                }
            }
        },

        async RenameFso(context, newName) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client = new api.FileSystemClient(auth_client);
            var fs = context.state.FsoToRename;
            if (fs != null) {
                var oldPath = fs.path! + "/" + fs.name;
                var newPath = fs.path! + "/" + newName;
                var notifications = await client.move(oldPath, newPath);
                for (var notification of notifications) {
                    console.log(notification);
                    await context.state.NotificationHandler!.HandleNotification(notification);
                }
            }
        },

        async DeleteSelectedItem(context) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client = new api.FileSystemClient(auth_client);
            var fs = context.state.SelectedFso;
            if (fs != null && fs != context.state.Root) {
                var parent = context.getters.getFsoParentById(fs.id) as api.FileSystemObjectContent;
                var notification = await client.delete(fs.path + "/" + fs.name);
                context.commit("SetPasteOperationDetails", new ext.PasteOperation());
                await context.state.NotificationHandler!.HandleNotification(notification);
            }
        },

        async PasteToTargetItem(context) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client = new api.FileSystemClient(auth_client);

            var fs = context.state.PasteTargetFso; //<=IMPORTANT!  This is the difference with PasteToSelectedItem below
            var pasteOp = context.state.PasteOperationDetails;

            if (fs == null || pasteOp == null || pasteOp.Type == ext.PasteOperationType.none || pasteOp.Fso == null)
                return;

            else {
                var source = pasteOp.Fso.path! + "/" + pasteOp.Fso.name;
                while (source.startsWith("//"))
                    source = source.replace("//", "/");

                var destination = fs.path + "/" + fs.name;
                while (destination.startsWith("//"))
                    destination = destination.replace("//", "/");

                if (pasteOp.Type == ext.PasteOperationType.copy) {
                    var notification = await client.copy(source, destination);
                    await context.state.NotificationHandler!.HandleNotification(notification);
                    context.commit("SetPasteOperationDetails", null);
                }
                else if (pasteOp.Type == ext.PasteOperationType.cut) {
                    var notifications = await client.move(source, destination);
                    for (var notification of notifications) {
                        console.log(notification);
                        await context.state.NotificationHandler!.HandleNotification(notification);
                    }
                    context.commit("SetPasteOperationDetails", null);
                }
            }
        },

        async PasteToSelectedItem(context) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client = new api.FileSystemClient(auth_client);

            var fs = context.state.SelectedFso;
            var pasteOp = context.state.PasteOperationDetails;

            if (fs == null || pasteOp == null || pasteOp.Type == ext.PasteOperationType.none || pasteOp.Fso == null)
                return;

            else {
                var source = pasteOp.Fso.path! + "/" + pasteOp.Fso.name;
                while (source.startsWith("//"))
                    source = source.replace("//", "/");

                var destination = fs.path + "/" + fs.name;
                while (destination.startsWith("//"))
                    destination = destination.replace("//", "/");

                if (pasteOp.Type == ext.PasteOperationType.copy) {
                    var notification = await client.copy(source, destination);
                    await context.state.NotificationHandler!.HandleNotification(notification);
                    context.commit("SetPasteOperationDetails", null);
                }
                else if (pasteOp.Type == ext.PasteOperationType.cut) {
                    var notifications = await client.move(source, destination);
                    for (var notification of notifications) {
                        console.log(notification);
                        await context.state.NotificationHandler!.HandleNotification(notification);
                    }
                    context.commit("SetPasteOperationDetails", null);
                }
            }
        },

        async ImportIntoSelectedItem(context, file: File) {
            var fso = context.state.SelectedFso!;
            var fullPath = fso.path! + "/" + fso.name!;
            fullPath = fullPath.replace("//", "/").replace("//", "/");

            const authClient = new AuthClient();
            await authClient.ensureToken();
            const client = new api.FileSystemClient(authClient);
            var notifications = await client.import(fullPath, { fileName: file.name, data: file as Blob });
            for (var notification of notifications) {
                await context.state.NotificationHandler!.HandleNotification(notification);
            }
        },

        async GetXL3DefaultServer(context) {
            try {

                if (context.state.XL3DefaultServer == null) {
                    const authClient = new AuthClient();
                    await authClient.ensureToken();
                    const client = new api.FileSystemClient(authClient);

                    var val = await client.getDefaulttXl3Server();
                    context.commit("SetXL3DefaultServer", val);
                }
            }
            catch (err) {
                this.dispatch("ShowToastError", err);
            }
        },

        async GetXL3DefaultStandaloneServer(context) {
            try {

                if (context.state.XL3DefaultServer == null) {
                    const authClient = new AuthClient();
                    await authClient.ensureToken();
                    const client = new api.FileSystemClient(authClient);

                    var val = await client.getDefaulttStandaloneXl3Server();
                    context.commit("SetXL3DefaultStandaloneServer", val);
                }
            }
            catch (err) {
                this.dispatch("ShowToastError", err);
            }
        },

        async ExpandExplorerFolders(context, { targetFso, secondary }: { targetFso: api.FileSystemObject, secondary: boolean }) {
            var targetPath = targetFso.path!;
            var targetName = targetFso.name!;

            // does user have folder access to each folder in the target path?
            var pathFolderNames = targetPath?.split("/");
            var testFolder: api.FileSystemObjectContent | undefined = context.state.Root!;
            var ancestorFolders = [testFolder];
            if (pathFolderNames) {
                for (var folderName of pathFolderNames) {
                    if (folderName === "")
                        continue;
                    testFolder = testFolder.folderContent!.find(fso => fso.name === folderName);
                    if (!testFolder || testFolder.folderRights == api.AccessRights.None)
                        return;
                    ancestorFolders.push(testFolder);
                }
            }


            if (targetName !== "/") {
                testFolder = testFolder.folderContent!.find(fso => fso.name === targetName);
                if (!testFolder || testFolder.folderRights == api.AccessRights.None)
                    return;
            }

            // we have access to each ancestor folder - let's expand them.
            var fsoExpandedState = secondary ? context.state.SecondaryFsoExpandedState : context.state.FsoExpandedState;
            for (var ancestorFolder of ancestorFolders) {
                fsoExpandedState[ancestorFolder.id] = true;
            }
        },

        async ToggleFavorite(context, path: string) {
            var auth_client = new AuthClient();
            await auth_client.ensureToken();
            var client = new api.FileSystemClient(auth_client);
            var favs = context.state.Favorites;

            if (favs != null) {
                try {
                    for (var i = 0; i < favs.length; i++) {
                        if (favs[i] == path) {
                            favs.splice(i, 1);
                            await client.setMyFavorites(path, false);
                            this.dispatch("ShowToast", new api.ToastNotification({
                                message: "Item removed from Favorites",
                                type: api.ToastType.Info
                            }));
                            return;
                        }
                    }
                    favs.push(path);
                    await client.setMyFavorites(path, true);
                    this.dispatch("ShowToast", new api.ToastNotification({
                        message: "Item added to Favorites",
                        type: api.ToastType.Info
                    }));
                }
                catch (err) {
                    this.dispatch("ShowToastError", err);
                }
            }
        }
    },

    getters: {
        getFsoHierarchyByPath: (state) =>
            (path: string): api.FileSystemObjectContent[] | null => {
                var parts = path.split('/');
                var result: api.FileSystemObjectContent[] = [];
                if (state.Root != null) {
                    var currentItem = state.Root;
                    result.push(currentItem);
                    for (var i = 0; i < parts.length; i++) {
                        if (currentItem != null && currentItem.folderContent != null)
                            for (var j = 0; j < currentItem.folderContent.length; j++) {
                                if (currentItem.folderContent[j].name!.toLowerCase() == parts[i].toLowerCase()) {
                                    currentItem = currentItem.folderContent[j];
                                    result.push(currentItem);
                                    break;
                                }
                            }
                    }
                }
                if (result.length == parts.length)
                    return result;
                return [];
            },

        getFsoHierarchyById: (state) =>
            (id: number): api.FileSystemObjectContent[] | null => {
                var recursiveSearch = function (id: number, fso: api.FileSystemObjectContent): api.FileSystemObjectContent[] | null {
                    if (fso.id === id) {
                        return [fso];
                    }
                    else if (fso.folderContent != null) {
                        for (let i = 0; i < fso.folderContent.length; i++) {
                            var result = recursiveSearch(id, fso.folderContent[i]);
                            if (result != null) {
                                result.push(fso);
                                return result;
                            }
                        }
                    }
                    return null;
                }

                if (state.Root != null) {
                    var result = recursiveSearch(id, state.Root);
                    if (result != null)
                        return result.reverse();
                }
                return null;
            },

        getFsoParentById: (state) =>
            (id: number): api.FileSystemObjectContent | null => {
                var recursiveSearch = function (id: number, fso: api.FileSystemObjectContent): api.FileSystemObjectContent | null {
                    if (fso.folderContent != null) {
                        for (let i = 0; i < fso.folderContent.length; i++) {
                            if (fso.folderContent[i].id == id)
                                return fso;
                            var result = recursiveSearch(id, fso.folderContent[i]);
                            if (result != null) {
                                return result;
                            }
                        }
                    }
                    return null;
                }

                if (state.Root != null) {
                    var result = recursiveSearch(id, state.Root);
                    return result;
                }
                return null;
            },

        getFsoById: (state) =>
            (id: number): api.FileSystemObjectContent | null => {
                var recursiveSearch = function (id: number, fso: api.FileSystemObjectContent): api.FileSystemObjectContent | null {
                    if (fso.id == id)
                        return fso;

                    if (fso.folderContent != null) {
                        for (let i = 0; i < fso.folderContent.length; i++) {
                            var result = recursiveSearch(id, fso.folderContent[i]);
                            if (result != null) {
                                return result;
                            }
                        }
                    }
                    return null;
                }

                if (state.Root != null) {
                    var result = recursiveSearch(id, state.Root);
                    return result;
                }
                return null;
            },

        getFsoByPath: (state) =>
            (path: string): api.FileSystemObjectContent | null => {
                var recursiveSearch = function (path: string, fso: api.FileSystemObjectContent): api.FileSystemObjectContent | null {
                    if ((fso.path + "/" + fso.name).replace("//","/") == path)
                        return fso;
                    if (!path.startsWith(fso.path!))
                        return null;
                    if (fso.folderContent != null) {
                        for (let i = 0; i < fso.folderContent.length; i++) {
                            var result = recursiveSearch(path, fso.folderContent[i]);
                            if (result != null) {
                                return result;
                            }
                        }
                    }
                    return null;
                }

                if (state.Root != null) {
                    var result = recursiveSearch(path, state.Root);
                    return result;
                }
                return null;
            },

        getSearchResults: (state) =>
            (localizer: Localizer | null): api.FileSystemObjectContent[] => {
                var search = state.SearchText;
                var rez: api.FileSystemObjectContent[] = [];
                if (search == null || search.length == 0)
                    return rez;

                var type: api.FileSystemType | null = null;
                if (localizer != null) {
                    const types = Object.keys(api.FileSystemType).filter((item) => { return isNaN(Number(item)); });
                    for (var i = 0; i < types.length; i++) {
                        var t: api.FileSystemType = api.FileSystemType[types[i] as keyof typeof api.FileSystemType];
                        var ts: string = localizer.FormatFileType(t);
                        var ts1 = "type: " + ts.toLowerCase() + " ";
                        var ts2 = "type:" + ts.toLowerCase() + " ";
                        if (search.startsWith(ts1)) {
                            type = t;
                            search = search.substring(ts1.length);
                            break;
                        }
                        if (search.startsWith(ts2)) {
                            type = t;
                            search = search.substring(ts2.length);
                            break;
                        }
                    }
                }

                var root = state.Root;

                var find = function (fso: api.FileSystemObjectContent) {
                    if (fso.folderContent != null) {
                        var children = fso.folderContent.filter(c => c.folderRights > api.AccessRights.None).sort((a, b) => {
                            var ord = a.name!.localeCompare(b.name!);
                            if (ord != 0)
                                ord = ord / Math.abs(ord);
                            if (a.objectType == api.FileSystemType.Folder)
                                ord -= 100;
                            if (b.objectType == api.FileSystemType.Folder)
                                ord += 100;
                            return ord;
                        });
                        for (var i = 0; i < children.length; i++) {
                            if (children[i].name!.toLowerCase().indexOf(search) >= 0 && (type == null || children[i].objectType == type ||
                                (children[i].objectType == api.FileSystemType.InteractiveProcess && type == api.FileSystemType.Process)))
                                rez.push(children[i]);
                        }
                        for (var i = 0; i < children.length; i++) {
                            find(children[i]);
                        }
                    }

                }
                if (root != null)
                    find(root);
                return rez;
            },

        FsoNameFromFsoType: (state) =>
            (type: api.FileSystemType) => {
                switch (type) {
                    case api.FileSystemType.DocxReport:
                        return "Word Report";
                    case api.FileSystemType.PptxReport:
                        return "PowerPoint Report";
                    case api.FileSystemType.PowerBI:
                        return "Power BI Report";
                    case api.FileSystemType.Generic:
                        return "Generic File";
                    case api.FileSystemType.InteractiveProcess:
                        return "Process";
                    case api.FileSystemType.ReportBook:
                        return "Report Book";
                    case api.FileSystemType.XL3:
                        return "Anaplan XL";
                    default:
                        return api.FileSystemType[type];
                }
            },

        OfficeReportMimetypeFromFsoType: (state) =>
            (type: api.FileSystemType) => {
                switch (type) {
                    case api.FileSystemType.DocxReport:
                        return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
                    case api.FileSystemType.PptxReport:
                        return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
                    default:
                        return null;
                }
            },

        OfficeReportExtensionFromFsoType: (state) =>
            (type: api.FileSystemType) => {
                switch (type) {
                    case api.FileSystemType.DocxReport:
                        return ".docx";
                    case api.FileSystemType.PptxReport:
                        return ".pptx";
                    default:
                        return null;
                }
            }
    }
};

export default ExplorerStore;
