import store from "../store/GlobalStore";
import * as api from "./ApiClient";
import { FluenceApiClientBase } from './ApiClientBase';

export class ActivityValidationError {
    public property: string;
    public error: string;

    constructor(property: string, error: string) {
        this.error = error;
        this.property = property;
    }
}

export class ActivityValidationFunction {
    public property: string;
    public func: Function;

    constructor(property: string, func: Function) {
        this.func = func;
        this.property = property;
    }
}

export abstract class ActivityValidator {
    protected activity: api.BaseProcessActivity;

    protected ValidatorFunctions: ActivityValidationFunction[] = [];
    public Errors: ActivityValidationError[] = [];

    constructor(activity: api.BaseProcessActivity) {
        this.activity = activity;
        this.AddStringRequired("name", "Name is required.");
    }

    public static For(activity: api.BaseProcessActivity): ActivityValidator {
        // Non-Interactive
        if (activity instanceof (api.DelayProcessActivity))
            return new DelayActivityValidator(activity as api.DelayProcessActivity);
        if (activity instanceof (api.IfProcessActivity))
            return new IfActivityValidator(activity as api.IfProcessActivity);
        if (activity instanceof (api.LogMessageProcessActivity))
            return new LogMessageActivityValidator(activity as api.LogMessageProcessActivity);
        if (activity instanceof (api.NoteProcessActivity))
            return new NoteActivityValidator(activity as api.NoteProcessActivity);
        if (activity instanceof (api.RunReportBookProcessActivity))
            return new RunReportBookProcessActivityValidator(activity as api.RunReportBookProcessActivity);
        if (activity instanceof (api.ScriptProcessActivity))
            return new ScriptActivityValidator(activity as api.ScriptProcessActivity);
        if (activity instanceof (api.SendEmailProcessActivity))
            return new SendEmailActivityValidator(activity as api.SendEmailProcessActivity);
        if (activity instanceof (api.SlackMessageProcessActivity))
            return new SlackMessageActivityValidator(activity as api.SlackMessageProcessActivity);
        if (activity instanceof (api.SqlServerStoredProcedureProcessActivity))
            return new SqlServerStoredProcedureActivityValidator(activity as api.SqlServerStoredProcedureProcessActivity);
        if (activity instanceof (api.StartProcessActivity))
            return new StartProcessActivityValidator(activity as api.StartProcessActivity);
        if (activity instanceof (api.StopProcessActivity))
            return new StopProcessActivityValidator(activity as api.StopProcessActivity);
        if (activity instanceof (api.LockProcessActivity))
            return new LockActivityValidator(activity as api.LockProcessActivity);
        if (activity instanceof (api.ForeachProcessActivity))
            return new ForeachActivityValidator(activity as api.ForeachProcessActivity);
        if (activity instanceof (api.SubProcessProcessActivity))
            return new SubProcessActivityValidator(activity as api.SubProcessProcessActivity);
        if (activity instanceof (api.VenaExportProcessActivity))
            return new VenaExportActivityValidator(activity as api.VenaExportProcessActivity);
        if (activity instanceof (api.VenaImportProcessActivity))
            return new VenaImportActivityValidator(activity as api.VenaImportProcessActivity);
        if (activity instanceof (api.AzureDataFactoryPipelineActivity))
            return new AzureDataFactoryPipelineActivityValidator(activity as api.AzureDataFactoryPipelineActivity);
        if (activity instanceof (api.PublishModelToAnalysisServicesActivity))
            return new PublishModelToAnalysisServicesActivityValidator(activity as api.PublishModelToAnalysisServicesActivity);
        if (activity instanceof (api.StagingTableImportProcessActivity))
            return new StagingTableImportProcessActivityValidator(activity as api.StagingTableImportProcessActivity);
        if (activity instanceof (api.ArchiveXL3ReportActivity))
            return new ArchiveXL3ReportActivityValidator(activity as api.ArchiveXL3ReportActivity);
        if (activity instanceof (api.ModelDataExportProcessActivity))
            return new ModelDataExportProcessActivityValidator(activity as api.ModelDataExportProcessActivity);
        if (activity instanceof (api.RunWorkflowProcessActivity))
            return new RunWorkflowProcessActivityValidator(activity as api.RunWorkflowProcessActivity);
        if (activity instanceof (api.AutomaticFileImportProcessActivity))
            return new AutomaticFileImportProcessActivityValidator(activity as api.AutomaticFileImportProcessActivity);
        if (activity instanceof (api.PublishModelToHanaActivity))
            return new PublishModelToHanaActivityValidator(activity as api.PublishModelToHanaActivity);
        if (activity instanceof (api.ADOTriggerActivity))
            return new ADOTriggerActivityValidator(activity as api.ADOTriggerActivity);
        if (activity instanceof (api.AnaplanWorkflowActivity))
            return new AnaplanWorkflowActivityValidator(activity as api.AnaplanWorkflowActivity);
        if (activity instanceof (api.ADOImportActivity))
            return new ADOImportActivityValidator(activity as api.ADOImportActivity);
        if (activity instanceof (api.HttpRequestProcessActivity))
            return new HttpRequestProcessActivityValidator(activity as api.HttpRequestProcessActivity);
        if (activity instanceof (api.ConnectorProcessActivity))
            return new ConnectorProcessActivityValidator(activity as api.ConnectorProcessActivity);
        if (activity instanceof (api.AutomaticHierarchySyncProcessActivity))
            return new AutomaticHierarchySyncValidator(activity as api.AutomaticHierarchySyncProcessActivity);
        // Interactive
        if (activity instanceof (api.DataEntryActivity))
            return new DataEntryActivityValidator(activity as api.DataEntryActivity);
        if (activity instanceof (api.FileExportProcessActivity))
            return new FileExportActivityValidator(activity as api.FileExportProcessActivity);
        if (activity instanceof (api.FileImportProcessActivity))
            return new FileImportActivityValidator(activity as api.FileImportProcessActivity);
        if (activity instanceof (api.ReportingActivity))
            return new ReportingActivityValidator(activity as api.ReportingActivity);
        if (activity instanceof (api.ReviewActivity))
            return new ReviewActivityValidator(activity as api.ReviewActivity);
        if (activity instanceof (api.ToDoProcessActivity))
            return new ToDoActivityValidator(activity as api.ToDoProcessActivity);

        throw "Unknown Activity '" + (activity as any).discriminator + "'";
    }

    private CheckParentProperties(propertyList: string[], err: ActivityValidationError): any {
        var testItem = this.activity as any;
        for (var i = 0; i < propertyList.length; i++) {
            var property = propertyList[i]
            testItem = testItem[property];
            if (!testItem) {
                break;
            }
        }
        return testItem;
    }

    protected AddInstanceRequired(propertyName: string, error: string) {
        this.ValidatorFunctions.push(new ActivityValidationFunction(propertyName, () => {
            var err = new ActivityValidationError(propertyName, error);
            this.RemoveError(err);

            var propertyList = propertyName.split(".");
            var testItem = this.CheckParentProperties(propertyList, err);
            if (testItem != 0 && !testItem)
                this.Errors.push(err);
        }));
    }

    protected AddStringRequired(propertyName: string, error: string) {
        this.ValidatorFunctions.push(new ActivityValidationFunction(propertyName, () => {
            var err = new ActivityValidationError(propertyName, error);
            this.RemoveError(err);

            var propertyList = propertyName.split(".");
            var finalProperty = propertyList.pop()!;
            var testItem = this.CheckParentProperties(propertyList, err);

            if (!testItem)
                this.Errors.push(err);
            else if (!testItem[finalProperty] || testItem[finalProperty] as string == "")
                this.Errors.push(err);
        }));
    }

    protected AddStringRequiredIf(propertyName: string, isRequired: () => boolean, error: string) {
        this.ValidatorFunctions.push(new ActivityValidationFunction(propertyName, () => {
            var err = new ActivityValidationError(propertyName, error);
            this.RemoveError(err);

            if (isRequired()) {
                var propertyList = propertyName.split(".");
                var finalProperty = propertyList.pop()!;
                var testItem = this.CheckParentProperties(propertyList, err);

                if (!testItem)
                    this.Errors.push(err);
                else if (!testItem[finalProperty] || testItem[finalProperty] as string == "")
                    this.Errors.push(err);
            }
        }));
    }

    protected AddNumberGreaterThan(propertyName: string, error: string, minValue: number) {
        this.ValidatorFunctions.push(new ActivityValidationFunction(propertyName, () => {
            var err = new ActivityValidationError(propertyName, error);
            this.RemoveError(err);

            var propertyList = propertyName.split(".");
            var finalProperty = propertyList.pop()!;
            var testItem = this.CheckParentProperties(propertyList, err);

            if (!testItem)
                this.Errors.push(err);
            else if (testItem[finalProperty] == null || testItem[finalProperty] as number < minValue)
                this.Errors.push(err);
        }));
    }

    protected AddNumberLessThan(propertyName: string, error: string, maxValue: number) {
        this.ValidatorFunctions.push(new ActivityValidationFunction(propertyName, () => {
            var err = new ActivityValidationError(propertyName, error);
            this.RemoveError(err);

            var propertyList = propertyName.split(".");
            var finalProperty = propertyList.pop()!;
            var testItem = this.CheckParentProperties(propertyList, err);

            if (!testItem)
                this.Errors.push(err);
            else if (testItem[finalProperty] == null || testItem[finalProperty] as number > maxValue)
                this.Errors.push(err);
        }));
    }

    protected AddDueDateFormat(propertyName: string, error: string) {
        this.ValidatorFunctions.push(new ActivityValidationFunction(propertyName, () => {
            var err = new ActivityValidationError(propertyName, error);
            this.RemoveError(err);

            var propertyList = propertyName.split(".");
            var finalProperty = propertyList.pop()!;
            var testItem = this.CheckParentProperties(propertyList, err);
            if (!testItem)
                return;

            var value = testItem[finalProperty] as string;
            if (!value)
                return;

            var sgn = value.substring(0, 1);
            var number = value.substring(1);
            if (!['=', '+', '-', '!', '@', '#', ' '].includes(sgn)) {
                this.Errors.push(err);
                return;
            }
            else if (sgn == ' ') {
                var absDate = Date.parse(number);
                if (isNaN(absDate))
                    this.Errors.push(err);
                return;
            }
            else {
                var absNumber = Number.parseFloat(number);
                if (isNaN(absNumber))
                    this.Errors.push(err);
                if (absNumber < 0 || absNumber > 120)
                    this.Errors.push(err);
            }
        }));
    }

    public AddGenericRule(err: ActivityValidationError, rule: (activity: api.BaseProcessActivity) => boolean) {
        this.ValidatorFunctions.push(new ActivityValidationFunction(err.property, () => {
            this.RemoveError(err);
            if (!rule(this.activity))
                this.Errors.push(err);
        }));
    }

    public Validate(property: string) {
        for (var i = 0; i < this.ValidatorFunctions.length; i++) {
            if (property == "*" || this.ValidatorFunctions[i].property == "*" || property == this.ValidatorFunctions[i].property)
                this.ValidatorFunctions[i].func();
        }
    }
    public HasErrors(property: string): boolean {
        for (var i = 0; i < this.Errors.length; i++) {
            if (this.Errors[i].property == property)
                return true;
        }
        return false;
    }

    protected RemoveError(err: ActivityValidationError) {
        this.Errors = this.Errors.filter(error => {
            return error.property !== err.property || error.error !== err.error
        })
    }
}

export abstract class NonInteractiveActivityValidator extends ActivityValidator {
    protected interactiveActivity: api.NonInteractiveProcessActivity;

    constructor(activity: api.NonInteractiveProcessActivity) {
        super(activity);
        this.interactiveActivity = activity;

        this.AddNumberGreaterThan("timeout", "Timeout (in seconds) must be greater than 0.", 0);
    }
}

export class DelayActivityValidator extends NonInteractiveActivityValidator {
    protected castActivity: api.DelayProcessActivity;

    constructor(activity: api.DelayProcessActivity) {
        super(activity);
        this.castActivity = activity;

        this.AddNumberGreaterThan("timeInSeconds", "Delay time (in seconds) must be greater than 0.", 0);
        this.AddNumberLessThan("timeInSeconds", " Delay time (in seconds) must be less than 3600.", 3600);
    }
}

export class IfActivityValidator extends NonInteractiveActivityValidator {
    protected castActivity: api.IfProcessActivity;

    constructor(activity: api.IfProcessActivity) {
        super(activity);
        this.castActivity = activity;

        this.AddStringRequired("condition", "Please provide a condition that evaluates to bool.");

        var err = new ActivityValidationError("dataSource", "Data source is required for sql scripts.");
        this.AddGenericRule(err, (activity) => {
            var sa = (activity as api.IfProcessActivity);
            if (sa != null && sa.type == api.IfProcessActivityScriptType.SQL && (sa.dataSource == null || sa.dataSource!.length == 0))
                return false;
            return true;
        });

        for (var i = 0; i < 50; i++) {
            let n = i;
            var err = new ActivityValidationError("parameters[" + n + "].key", "Invalid name for parameter #" + n);
            this.AddGenericRule(err, (activity) => {
                var sa = (activity as api.IfProcessActivity);
                if (sa == null || sa.parameters == null || sa.parameters.length <= n || sa.type != api.IfProcessActivityScriptType.SQL)
                    return true;
                var p = sa.parameters[n];
                return p.key != null && p.key != '';
            });
        }
    }
}

export class LogMessageActivityValidator extends NonInteractiveActivityValidator {
    protected castActivity: api.LogMessageProcessActivity;

    constructor(activity: api.LogMessageProcessActivity) {
        super(activity);
        this.castActivity = activity;

        this.AddInstanceRequired("level", "Please provide a log level.");
        this.AddStringRequired("message", "Please provide a message.");
    }
}

export class NoteActivityValidator extends NonInteractiveActivityValidator {
    protected castActivity: api.NoteProcessActivity;

    constructor(activity: api.NoteProcessActivity) {
        super(activity);
        this.castActivity = activity;
    }
}

export class ScriptActivityValidator extends NonInteractiveActivityValidator {
    protected castActivity: api.ScriptProcessActivity;

    constructor(activity: api.ScriptProcessActivity) {
        super(activity);
        this.castActivity = activity;
    }
}

export class SendEmailActivityValidator extends NonInteractiveActivityValidator {
    protected castActivity: api.SendEmailProcessActivity;

    constructor(activity: api.SendEmailProcessActivity) {
        super(activity);
        this.castActivity = activity;

        this.AddStringRequired("to", "To address is empty.");
        this.AddStringRequired("subject", "Subject is empty.");
        this.AddStringRequired("body", "Body is empty.");
    }
}

export class SlackMessageActivityValidator extends NonInteractiveActivityValidator {
    protected castActivity: api.SlackMessageProcessActivity;

    constructor(activity: api.SlackMessageProcessActivity) {
        super(activity);
        this.castActivity = activity;

        this.AddStringRequired("message", "Message is required.");
        this.AddStringRequired("dataSource", "Data source is required.");
        this.AddGenericRule(
            new ActivityValidationError("slackChannelOverride", "Channel needs to start with a '#' or an '@' symbol."),
            (activity) => {
                var slackActivity = activity as api.SlackMessageProcessActivity;
                if (slackActivity.slackChannelOverride != null && slackActivity.slackChannelOverride != "")
                    if (!slackActivity.slackChannelOverride.startsWith('@') && !slackActivity.slackChannelOverride.startsWith('#'))
                        return false;
                return true;
            }
        );
    }
}

export class SqlServerStoredProcedureActivityValidator extends NonInteractiveActivityValidator {
    protected castActivity: api.SqlServerStoredProcedureProcessActivity;

    constructor(activity: api.SqlServerStoredProcedureProcessActivity) {
        super(activity);
        this.castActivity = activity;

        this.AddStringRequiredIf("dataSource", () => this.castActivity.type == api.CalculationType.StoredProcedure, "Data source is required.");
        this.AddStringRequiredIf("spName", () => this.castActivity.type == api.CalculationType.StoredProcedure, "Name of stored procedure is required.");

        for (var i = 0; i < 50; i++) {
            let n = i;
            var err = new ActivityValidationError("parameters[" + n + "].key", "Invalid name for parameter #" + n);
            this.AddGenericRule(err, (activity) => {
                var sa = (activity as api.SqlServerStoredProcedureProcessActivity);
                if (sa == null || sa.parameters == null || sa.parameters.length <= n)
                    return true;
                var p = sa.parameters[n];
                return p.key != null && p.key != '';
            });
        }
    }
}

export class StartProcessActivityValidator extends NonInteractiveActivityValidator {
    protected castActivity: api.StartProcessActivity;

    constructor(activity: api.StartProcessActivity) {
        super(activity);
        this.castActivity = activity;
    }
}

export class StopProcessActivityValidator extends NonInteractiveActivityValidator {
    protected castActivity: api.StopProcessActivity;

    constructor(activity: api.StopProcessActivity) {
        super(activity);
        this.castActivity = activity;
    }
}

export class SubProcessActivityValidator extends NonInteractiveActivityValidator {
    protected castActivity: api.SubProcessProcessActivity;

    constructor(activity: api.SubProcessProcessActivity) {
        super(activity);
        this.castActivity = activity;

        this.AddGenericRule(
            new ActivityValidationError("subProcess", "SubProcess does not contain a Start Activity."),
            (activity) => {
                var subProcessActivity = activity as api.SubProcessProcessActivity;
                if (subProcessActivity.subProcess) {
                    if (subProcessActivity.subProcess.nodes) {
                        var nodes = subProcessActivity.subProcess.nodes;
                        if (nodes.some((node) => node.activity instanceof api.StartProcessActivity))
                            return true;
                    }
                    return false;
                }
                return true;
            }
        );
    }
}

export class LockActivityValidator extends SubProcessActivityValidator {
    protected castActivity: api.LockProcessActivity;

    constructor(activity: api.LockProcessActivity) {
        super(activity);
        this.castActivity = activity;

        this.AddStringRequired("lock", "Lock name is required.");
    }
}

export class ForeachActivityValidator extends SubProcessActivityValidator {
    protected castActivity: api.ForeachProcessActivity;

    constructor(activity: api.ForeachProcessActivity) {
        super(activity);
        this.castActivity = activity;

        this.AddStringRequired("dimensionQuery", "Dimension query is required.");
    }
}

export class VenaExportActivityValidator extends NonInteractiveActivityValidator {
    protected castActivity: api.VenaExportProcessActivity;

    constructor(activity: api.VenaExportProcessActivity) {
        super(activity);
        this.castActivity = activity;
    }
}

export class VenaImportActivityValidator extends NonInteractiveActivityValidator {
    protected castActivity: api.VenaImportProcessActivity;

    constructor(activity: api.VenaImportProcessActivity) {
        super(activity);
        this.castActivity = activity;
    }
}

export class AzureDataFactoryPipelineActivityValidator extends NonInteractiveActivityValidator {
    protected castActivity: api.AzureDataFactoryPipelineActivity;

    constructor(activity: api.AzureDataFactoryPipelineActivity) {
        super(activity);
        this.castActivity = activity;

        this.AddStringRequired("dataSource", "Data Source name is required.");
        //this.AddStringRequired("resourceGroupName", "Resource Group name is required.");
        //this.AddStringRequired("dataFactoryName", "Data Factory name is required.");
        this.AddStringRequired("pipelineName", "Pipeline name is required.");
    }
}

export class RunReportBookProcessActivityValidator extends NonInteractiveActivityValidator {
    protected castActivity: api.RunReportBookProcessActivity;

    constructor(activity: api.RunReportBookProcessActivity) {
        super(activity);
        this.castActivity = activity;

        this.AddInstanceRequired("activityFiles", "At least one report-book object is required.");
        for (var i = 0; i < 10; i++) {
            let n = i;
            this.AddGenericRule(
                new ActivityValidationError("activityFiles[" + n + "]", "Report book " + (n + 1) + " has unassigned parameters."),
                (activity) => {
                    var rbActivity = activity as api.RunReportBookProcessActivity;
                    if (rbActivity.activityFiles && rbActivity.activityFiles[n] && rbActivity.activityFiles[n].newParameters) {
                        if (rbActivity.activityFiles[n].newParameters!.parameters && rbActivity.activityFiles[n].newParameters!.parameters!.length > 0) {
                            return rbActivity.activityFiles[n].newParameters!.parameters!.every(p => p.currentValue && p.currentValue.key && p.currentValue.key != "");
                        }
                    }
                    return true;
                }
            );
        }
    }
}

export abstract class InteractiveActivityValidator extends ActivityValidator {
    protected interactiveActivity: api.InteractiveProcessActivity;

    constructor(activity: api.InteractiveProcessActivity) {
        super(activity);
        this.interactiveActivity = activity;

        this.AddStringRequired("contributor", "Contributor is required.");
        this.AddStringRequired("supervisor", "Supervisor is required.");
        this.AddDueDateFormat("dueDate", "Invalid due date")
    }
}

export class DataEntryActivityValidator extends InteractiveActivityValidator {
    protected castActivity: api.DataEntryActivity;

    constructor(activity: api.DataEntryActivity) {
        super(activity);
        this.castActivity = activity;
    }
}

export class FileExportActivityValidator extends InteractiveActivityValidator {
    protected castActivity: api.FileExportProcessActivity;

    constructor(activity: api.FileExportProcessActivity) {
        super(activity);
        this.castActivity = activity;

        this.AddStringRequired("dataSource", "Data Source is required.");
        this.AddStringRequired("query", "Query is required.");
        this.AddStringRequired("fileType", "File Type is required.");

        for (var i = 0; i < 50; i++) {
            let n = i;
            var err = new ActivityValidationError("parameters[" + n + "].key", "Invalid name for parameter #" + n);
            this.AddGenericRule(err, (activity) => {
                var sa = (activity as api.FileExportProcessActivity);
                if (sa == null || sa.parameters == null || sa.parameters.length <= n)
                    return true;
                var p = sa.parameters[n];
                return p.key != null && p.key != '';
            });
        }
    }
}

export class FileImportActivityValidator extends InteractiveActivityValidator {
    protected castActivity: api.FileImportProcessActivity;

    constructor(activity: api.FileImportProcessActivity) {
        super(activity);
        this.castActivity = activity;

        //this.AddStringRequired("dataSource", "Data Source is required.");
        //this.AddStringRequired("tableName", "Table name is required.");
    }
}

export class ReportingActivityValidator extends InteractiveActivityValidator {
    protected castActivity: api.ReportingActivity;

    constructor(activity: api.ReportingActivity) {
        super(activity);
        this.castActivity = activity;
    }
}

export class ReviewActivityValidator extends InteractiveActivityValidator {
    protected castActivity: api.ReviewActivity;

    constructor(activity: api.ReviewActivity) {
        super(activity);
        this.castActivity = activity;
    }
}

export class ToDoActivityValidator extends InteractiveActivityValidator {
    protected castActivity: api.ToDoProcessActivity;

    constructor(activity: api.ToDoProcessActivity) {
        super(activity);
        this.castActivity = activity;

        //this.AddStringRequired("description", "Description is required.");
    }
}

export class ModelDataExportProcessActivityValidator extends NonInteractiveActivityValidator {
    protected castActivity: api.ModelDataExportProcessActivity;

    constructor(activity: api.ModelDataExportProcessActivity) {
        super(activity);
        this.castActivity = activity;

        this.AddStringRequired("modelViewOrModel", "Model or View Name is required.");
    }
}

export class PublishModelToAnalysisServicesActivityValidator extends NonInteractiveActivityValidator {
    protected castActivity: api.PublishModelToAnalysisServicesActivity;

    constructor(activity: api.PublishModelToAnalysisServicesActivity) {
        super(activity);
        this.castActivity = activity;

        this.AddStringRequired("modelName", "Model Name is required.");
    }
}


export class PublishModelToHanaActivityValidator extends NonInteractiveActivityValidator {
    protected castActivity: api.PublishModelToHanaActivity;

    constructor(activity: api.PublishModelToHanaActivity) {
        super(activity);
        this.castActivity = activity;

        this.AddStringRequired("modelName", "Model Name is required.");
        this.AddStringRequired("datasource", "Datasource is required.");
        this.AddStringRequired("schema", "Schema is required.");
    }
}
export class ADOTriggerActivityValidator extends NonInteractiveActivityValidator {
    protected castActivity: api.ADOTriggerActivity;

    constructor(activity: api.ADOTriggerActivity) {
        super(activity);
        this.castActivity = activity;

        this.AddStringRequired("datasource", "Datasource is required.");
        this.AddStringRequired("actionType", "Action type is required.");
        this.AddStringRequired("actionID", "Action is required.");
    }
}
export class AnaplanWorkflowActivityValidator extends NonInteractiveActivityValidator {
    protected castActivity: api.AnaplanWorkflowActivity;

    constructor(activity: api.AnaplanWorkflowActivity) {
        super(activity);
        this.castActivity = activity;

        this.AddStringRequired("datasource", "Datasource is required.");
        this.AddStringRequired("workflowID", "Workflow is required.");
        this.AddStringRequired("workflowName", "Workflow name is required.");
    }
}
export class ADOImportActivityValidator extends NonInteractiveActivityValidator {
    protected castActivity: api.ADOImportActivity;

    constructor(activity: api.ADOImportActivity) {
        super(activity);
        this.castActivity = activity;

        this.AddStringRequired("datasource", "Datasource is required.");
        this.AddStringRequired("dataset", "Dataset is required.");
        this.AddStringRequired("tablename", "Table is required.");
    }
}
export class StagingTableImportProcessActivityValidator extends NonInteractiveActivityValidator {
    protected castActivity: api.StagingTableImportProcessActivity;

    constructor(activity: api.StagingTableImportProcessActivity) {
        super(activity);
        this.castActivity = activity;

        this.AddStringRequired("modelMappingName", "Model Mapping is required.");
    }
}

export class ArchiveXL3ReportActivityValidator extends NonInteractiveActivityValidator {
    protected castActivity: api.ArchiveXL3ReportActivity;

    constructor(activity: api.ArchiveXL3ReportActivity) {
        super(activity);
        this.castActivity = activity;

        //this.AddStringRequired("modelMappingName", "Model Mapping is required.");
    }
}


export class RunWorkflowProcessActivityValidator extends NonInteractiveActivityValidator {
    protected castActivity: api.RunWorkflowProcessActivity;

    constructor(activity: api.RunWorkflowProcessActivity) {
        super(activity);
        this.castActivity = activity;

        this.AddInstanceRequired("fsWorkflow", "Workflow is required.");
    }
}


export class AutomaticFileImportProcessActivityValidator extends NonInteractiveActivityValidator {
    protected castActivity: api.AutomaticFileImportProcessActivity;

    constructor(activity: api.AutomaticFileImportProcessActivity) {
        super(activity);
        this.castActivity = activity;

        this.AddStringRequired("dataSource", "Data Source is required.");
        this.AddStringRequired("tableName", "Staging Table Name is required.");
    }
}

export class ConnectorProcessActivityValidator extends NonInteractiveActivityValidator {
    protected castActivity: api.ConnectorProcessActivity;

    constructor(activity: api.ConnectorProcessActivity) {
        super(activity);
        this.castActivity = activity;
    }
}

export class AutomaticHierarchySyncValidator extends NonInteractiveActivityValidator {
    protected castActivity: api.AutomaticHierarchySyncProcessActivity;

    constructor(activity: api.AutomaticHierarchySyncProcessActivity) {
        super(activity);
        this.castActivity = activity;

        this.AddStringRequired("dataSource", "Data Source is required.");
        this.AddStringRequired("dimensionName", "Dimension is required.");
        //this.AddStringRequired("hierarchyName", "Hierarchy is required.");
       // this.AddStringRequired("fileName", "File Name is required.");
    }
}

export class HttpRequestProcessActivityValidator extends NonInteractiveActivityValidator {
    protected castActivity: api.HttpRequestProcessActivity;

    constructor(activity: api.HttpRequestProcessActivity) {
        super(activity);
        this.castActivity = activity;

        //this.AddStringRequired("dataSource", "Data Source is required.");
        //this.AddStringRequired("tableName", "Staging Table Name is required.");
    }
}
