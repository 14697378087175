<template>
    <div class="spinner">
        <f-spin :spinning="true" size="large"></f-spin>
    </div>
</template>
<style scoped>
.spinner {
    width: 100%;
    text-align: center;
    padding: 25px;
}
</style>
